import api from '../_api';

// Get the Key Accounts List
export const getKeyAccounts = () => api.get('/admin/key-account-clients');

// Create a Key Account
export const createKeyAccount = (account) => api.post(
  '/admin/key-account-clients',
  account,
);

// Get the Key Account details
export const getKeyAccount = (code) => api.get(`/admin/key-account-clients/${code}`);

// Update some of the Key Account details
export const updateKeyAccount = ({ data, code }) => api.patch(
  `/admin/key-account-clients/${code}`,
  data,
);

// Update all the Key Account details
export const updateAllKeyAccount = ({ data, code }) => api.put(
  `/admin/key-account-clients/${code}`,
  data,
);

// Get the Key Account clients list
export const getKeyAccountClients = (code) => api.get(`/admin/key-account-clients/${code}/clients`);

// Import a Key Account clients list
export const importKeyAccountClients = ({ code, formData }) => api.post(
  `/admin/key-account-clients/${code}/import-clients`,
  formData,
  {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data',
  },
);

// Get all Key Accounts statistics
export const getKeyAccountsStats = (startAt, endAt) => api.get(
  '/admin/stats/key-account-clients',
  {
    params: {
      startAt,
      endAt,
    },
  },
);

// Get all Key Account clients statistics
export const getKeyAccountClientsStats = async (keyAccountId, startAt, endAt) => {
  const { data } = await api.get(
    `/admin/stats/key-account-clients/${keyAccountId}/clients`,
    {
      params: {
        startAt,
        endAt,
      },
    },
  );
  return data;
};

// Get the Key Account Admins List
export const getKeyAccountAdmins = (code) => api.get(`/admin/key-account-clients/${code}/admin`);

// Create a Key Account Admin
export const createKeyAccountAdmin = ({ code, data }) => api.post(
  `/admin/key-account-clients/${code}/admin`,
  data,
);

// Get a Key Account Admin details
export const getKeyAccountAdmin = (id) => api.get(`/admin/key-account-client-admin/${id}`);

// Update a Key Account Admin
export const updateKeyAccountAdmin = ({ id, data }) => api.patch(
  `/admin/key-account-client-admin/${id}`,
  data,
);

// Delete a Key Account Admin
export const deleteKeyAccountAdmin = (id) => api.delete(`/admin/key-account-client-admin/${id}`);

// Update a Key Account Admin
export const updateKeyAccountAdminPassword = ({ id, data }) => api.put(
  `/admin/key-account-client-admin/${id}/password`,
  data,
);

export const getMyKeyAccountClient = async () => {
  const result = await api.get('/admin/my-key-account-client');
  return result.data.keyAccountClient;
};

// Get all Key Account Admin statistics
export const getKeyAccountAdminStats = (startAt, endAt) => api.get(
  '/admin/my-key-account-client/stats',
  {
    params: {
      startAt,
      endAt,
    },
  },
);

// Get all Key Account Admin orders
export const getKeyAccountAdminOrders = (values) => api.post('/admin/my-key-account-client/order', {
  ...values,
  months: values.months || [],
});

// Get a Key Account Admin order details
export const getKeyAccountAdminOrder = (id) => api.get(`/admin/my-key-account-client/order/${id}`);

// Get an order invoice url
export const getInvoiceUrl = ({ orderNumber, invoiceType }) => api.get(
  `/admin/my-key-account-client/order/${orderNumber}/invoice-url`,
  {
    params: {
      invoiceType,
    },
  },
);

// Delete one or many orders
export const deleteOrdersByNumbers = ({ orderNumbers, comment }) => api.delete(
  '/admin/my-key-account-client/order',
  {
    data: {
      orderNumbers,
      comment,
    },
  },
);

// Get all Key Account Admin clients
export const getKeyAccountAdminClients = (values) => api.post('/admin/my-key-account-client/client', values);

// Export the clients list
export const getKeyAccountAdminClientsExport = () => api.get('/admin/my-key-account-client/client/export');
