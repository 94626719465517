import React from 'react';
import PropTypes from 'prop-types';

// Icons
import InfoIcon from '../../../public/icons/info.svg';
import CheckIcon from '../../../public/icons/check_circle.svg';

import styles from './FlashMessage.module.scss';

function FlashMessage({ message, color }) {
  return (
    <div
      className={`
        ${styles.flash}
        ${color ? styles[color] : ''}
        flex a-center
        flash-message
      `}
    >
      {color === 'success' || color === 'important' ? (
        <CheckIcon className={styles.icon} />
      ) : (
        <InfoIcon className={styles.icon} />
      )}
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
}

FlashMessage.propTypes = {
  message: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'important', 'warning', 'alert', 'error', 'success']),
};

FlashMessage.defaultProps = {
  message: '',
  color: 'primary',
};

export default FlashMessage;
